import React from "react";
import { useSelector } from "react-redux";
// import Logo from "./mastertacket.png";
import Logo from "../../assets/images/mastertacket.png";
import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { venderData } = useSelector((state) => state.vendorDetails);

  const onClickLogo = () => {
    if (location?.pathname !== "/not-found") {
      navigate("/");
    }
  };

  return (
    <div className="header-container d-flex align-items-center pl-5 pr-5">
      <img
        src={venderData?.company_logo_url || Logo}
        alt="Logo"
        className="logo cursor-pointer"
        onClick={onClickLogo}
      />
    </div>
  );
};

export default Header;
